import { Injectable } from '@angular/core';
import { AccessTokenEntity, AccountEntity } from '@azure/msal-common';
import { app, authentication } from '@microsoft/teams-js';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { inTeams } from '../helpers/teams.helper';
import { MsalService } from '@azure/msal-angular';


@Injectable({
  providedIn: 'root',
})
export class TeamsService {

  private theme = new BehaviorSubject<string>('web');
  public readonly theme$ = this.theme.asObservable();
  private initialized = new ReplaySubject<boolean>(1);
  public readonly initialized$ = this.initialized.asObservable();


  get teamsContext(): Promise<app.Context | undefined> {
    return app?.getContext();
  }

  constructor(
    private readonly msalService: MsalService,
  ) {
  }

  async init() {
    const setTheme = (theme: string) => {
      switch (theme) {
        case 'dark':
        case 'contrast':
          this.theme.next('teams-dark');
          break;
        default:
          this.theme.next('web');
          break;
      }
    };
    if (inTeams()) {
      console.log('Initialize Auth & Theme in Teams');
      try {
        await app.initialize();

        const context = await this.teamsContext;
        setTheme(`${ context?.app?.theme }`);
        app.registerOnThemeChangeHandler(theme => setTheme(theme));

        const token = await authentication.getAuthToken();
        const decodedToken: { [key: string]: any; } = jwtDecode(token) as { [key: string]: any; };
        console.log(decodedToken);
        this.registerTeamsTokenWithMsal(decodedToken, token);
        app.notifySuccess();

        this.initialized.next(true);

        return true;
      } catch (e: any) {
        console.error(e);
        app.notifyFailure({
          reason: app.FailedReason.AuthFailed,
          message: e.message,
        });
        return false;
      }
    } else {
      console.log('Initialize Theme in Web');
      const query = window.matchMedia('(prefers-color-scheme: dark)');
      setTheme(query.matches ? 'dark' : 'light');
      query.addEventListener('change', event => {
        setTheme(event.matches ? 'dark' : 'light');
      });
      this.initialized.next(false);
      return true;
    }
  }

  private registerTeamsTokenWithMsal(accessToken: { [key: string]: any; }, accessTokenString: string): void {
    const accountEntity = this.getAccountEntity(accessToken);
    const accessTokenEntity = this.getAccessTokenEntity(accessToken, accessTokenString);


    const browserStorage = (this.msalService.instance as any).controller.browserStorage;
    browserStorage.setAccount(accountEntity);
    browserStorage.setAccessTokenCredential(accessTokenEntity);

    this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
  }

  private getAccountEntity(accessToken: { [key: string]: any; }): AccountEntity {
    const account = new AccountEntity();
    Object.assign(account, {
      authorityType: 'MSSTS',
      // fixed
      environment: 'login.windows.net',
      // oid.tid
      homeAccountId: `${ accessToken['oid'] }.${ accessToken['tid'] }`,
      // oid
      localAccountId: accessToken['oid'],
      idTokenClaims: accessToken,
      // tid
      realm: accessToken['tid'],
      // upn
      username: accessToken['upn'],
    });

    return account;
  }

  private getAccessTokenEntity(accessToken: { [key: string]: any; }, accessTokenString: string): AccessTokenEntity {
    return {
      cachedAt: accessToken['iat'],
      clientId: (accessToken['aud'] as string).substring((accessToken['aud'] as string).lastIndexOf('/') + 1),
      credentialType: 'AccessToken',
      environment: 'login.windows.net',
      expiresOn: accessToken['exp'],
      extendedExpiresOn: accessToken['exp'],
      homeAccountId: `${ accessToken['oid'] }.${ accessToken['tid'] }`,
      realm: accessToken['tid'],
      secret: accessTokenString,
      target: accessToken['scp'],
      tokenType: 'Bearer',
    }satisfies AccessTokenEntity;
  }

}
