// from https://github.com/wictorwilen/msteams-react-base-component/blob/c3d941e3467365c23d8e8f4f2c067c4b0eb6a032/src/useTeams.ts#L10
import * as microsoftTeams from '@microsoft/teams-js';


export function inTeams() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const microsoftTeamsLib = microsoftTeams || window['microsoftTeams'];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  return !!(
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.navigator.userAgent.includes('Teams/') ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  );
}
